import React, { useEffect } from 'react';
import { graphql, withPrefix } from 'gatsby';
import cn from 'classnames';

import { drids } from '../_constants/drids';

import { Page } from 'components/_shared/Page';
import { PositionList } from 'components/Position';

import { useUserActions } from 'state';

import { loaderEvents } from '_events';
import { parseMarkdown, sortByKey } from 'utils';
import { useUser, useUserAccess, useUserApplications } from 'state/hooks';

const Benefit = ({ className, title, text, iconKey }) => {
  const _className = cn('benefit', 'devrev-grid-item', className);

  const icon = withPrefix(
    `../assets/icons/benefits/benefit-icon-${iconKey}.svg`
  );

  return (
    <div className={_className}>
      {icon && (
        <div className='benefit-icon mb-4 flex-shrink-0 '>
          <img src={icon} alt={title} />
        </div>
      )}
      <div>
        <h4>{title}</h4>
        <p className='opacity-50'>{text}</p>
      </div>
    </div>
  );
};

export default function PageJoinUs(props) {
  const user = useUser();
  const { isAuthenticated } = useUserAccess();
  const { isLoaded: areApplicationsLoaded } = useUserApplications();

  const {
    strapiJoinUs: page = {},
    allStrapiBenefit: { nodes: unOrderBenefits = [] }
  } = props.data;
  const { getUserApplications } = useUserActions();

  const benefits = sortByKey(unOrderBenefits, { equalKey: 'benefit_title' });

  useEffect(() => {
    if (!isAuthenticated) return;

    const toggleLoader = isOpen => {
      !areApplicationsLoaded && loaderEvents.toggleLoader({ isOpen });
    };

    toggleLoader(true);

    (async () => {
      await getUserApplications();
      toggleLoader(false);
    })();
  }, [user.email, getUserApplications, isAuthenticated, areApplicationsLoaded]);

  const renderVideo = () => (
    <div className='relative w-full'>
      <div className='join-devrev-vid'>
        <div className='pr-3 md:pr-6'>
          <div className='embed-container'>
            <iframe
              title='Join DevRev'
              src='https://player.vimeo.com/video/554952550'
              frameBorder='0'
              webkitallowfullscreen='true'
              mozallowfullscreen='true'
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className='video-limit absolute left-0 bottom-14 w-full h-1' />
    </div>
  );

  const renderCharacteristics = () => (
    <div className='join-devrev-benefits pt-3 md:pt-12 devrev-row-container'>
      <div className='devrev-row-item'>
        <h4>{page.DevRev_Value1_Title}</h4>
        {parseMarkdown(page.DevRev_Value_1_body.data.DevRev_Value_1_body)}
      </div>

      <div className='devrev-row-item'>
        <h4>{page.DevRev_Value2_Title}</h4>
        {parseMarkdown(page.DevRev_Value2_body.data.DevRev_Value2_body)}
      </div>

      <div className='devrev-row-item'>
        <h4>{page.DevRev_Value3_Title}</h4>
        {parseMarkdown(page.DevRev_Value3_body.data.DevRev_Value3_body)}
      </div>

      <div className='devrev-row-item'>
        <h4>{page.DevRev_Value4_Title}</h4>
        {parseMarkdown(page.DevRev_Value4_body.data.DevRev_Value4_body)}
      </div>
    </div>
  );

  const renderBenefits = () => {
    return (
      <section className='section-benefits bg-teal-50 pt-6 md:pt-12 pb-8 pl-3 md:pl-6 text-teal-900'>
        <div className='w-full max-w-devrev-extra mx-auto'>
          <div className='block md:max-w-2xl text-lg md:text-xl lg:text-2xl leading-tight'>
            {parseMarkdown(page.Benefits_main.data.Benefits_main)}
          </div>

          <div className='w-full flex'>
            <div className='lg:w-80 lg:mr-12 flex-shrink-0'></div>
            <div className='devrev-grid-container mt-12 lg:mt-24'>
              {benefits.map(it => (
                <Benefit
                  key={it.benefit_title}
                  title={it.benefit_title}
                  text={it.benefit_body.data.benefit_body}
                  iconKey={it.key}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };

  const description = parseMarkdown(
    page.join_us_subtitle.data.join_us_subtitle
  );

  return (
    <Page
      className='page-join'
      meta={{
        title: page.seotitle,
        description: page.seodescription,
        image: page.preview_img[0]?.url
      }}
      drid={drids.PAGE.JOIN_US}
    >
      <div className='header-spacer h-24' />
      <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 pl-3 md:pl-6 relative lg:pt-12 lg:flex-row'>
        <div className='lg:w-80 lg:mr-12 flex-shrink-0'>
          <div className=' text-3xl flex justify-center text-center md:text-left buggy-align md:justify-start pb-6 md:text-4xl lg:text-5xl leading-none'>
            <h2 className='font-title w-full md:w-2/3 lg:w-auto'>
              {page.join_us_title}
            </h2>
          </div>
          <div className='buggy-align mx-auto w-full md:w-full mb-12 lg:m-0 text-center md:text-left text-lg md:text-xl px-3 md:px-0 lg:text-2xl lg:max-width-none leading-tight'>
            <div>{description}</div>
          </div>
        </div>

        <section>
          {renderVideo()}
          {renderCharacteristics()}
        </section>
      </div>

      {renderBenefits()}

      <PositionList id='positions' />
    </Page>
  );
}

export const query = graphql`
  query PageJoinUsQuery {
    strapiJoinUs {
      DevRev_Value1_Title
      DevRev_Value2_Title
      DevRev_Value2_body {
        data {
          DevRev_Value2_body
        }
      }
      DevRev_Value3_Title
      DevRev_Value3_body {
        data {
          DevRev_Value3_body
        }
      }
      DevRev_Value4_Title
      DevRev_Value4_body {
        data {
          DevRev_Value4_body
        }
      }
      DevRev_Value_1_body {
        data {
          DevRev_Value_1_body
        }
      }
      join_us_subtitle {
        data {
          join_us_subtitle
        }
      }
      join_us_title
      seotitle
      seodescription
      Benefits_main {
        data {
          Benefits_main
        }
      }
      preview_img {
        url
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 80, width: 1440)
          }
        }
      }
    }
    allStrapiBenefit {
      nodes {
        benefit_body {
          data {
            benefit_body
          }
        }
        benefit_title
        order
        key
      }
    }
  }
`;
